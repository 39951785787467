
.custom-checkbox-footer label {
  font-size: 0.9rem !important;
}
.container-uploads svg {
  width: 70px;
}
[dir] .b-form-tags.form-control {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
