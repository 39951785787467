.flatpickr-calendar .flatpickr-day[data-v-5672fde5] {
  color: #6e6b7b;
}
[dir] .flatpickr-calendar .flatpickr-day.today[data-v-5672fde5] {
  border-color: #0875d6;
}
.flatpickr-calendar .flatpickr-day.today[data-v-5672fde5]:hover {
  color: #6e6b7b;
}
[dir] .flatpickr-calendar .flatpickr-day.today[data-v-5672fde5]:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-day.selected[data-v-5672fde5], .flatpickr-calendar .flatpickr-day.selected[data-v-5672fde5]:hover {
  color: #fff;
}
[dir] .flatpickr-calendar .flatpickr-day.selected[data-v-5672fde5], [dir] .flatpickr-calendar .flatpickr-day.selected[data-v-5672fde5]:hover {
  background: #0875d6;
  border-color: #0875d6;
}
[dir] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  background: #7dbffa;
  border-color: #7dbffa;
}
[dir=ltr] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir=ltr] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  box-shadow: -5px 0 0 #7dbffa, 5px 0 0 #7dbffa;
}
[dir=rtl] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir=rtl] .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  box-shadow: 5px 0 0 #7dbffa, -5px 0 0 #7dbffa;
}
.flatpickr-calendar .flatpickr-day.startRange[data-v-5672fde5], .flatpickr-calendar .flatpickr-day.endRange[data-v-5672fde5], .flatpickr-calendar .flatpickr-day.startRange[data-v-5672fde5]:hover, .flatpickr-calendar .flatpickr-day.endRange[data-v-5672fde5]:hover {
  color: #fff;
}
[dir] .flatpickr-calendar .flatpickr-day.startRange[data-v-5672fde5], [dir] .flatpickr-calendar .flatpickr-day.endRange[data-v-5672fde5], [dir] .flatpickr-calendar .flatpickr-day.startRange[data-v-5672fde5]:hover, [dir] .flatpickr-calendar .flatpickr-day.endRange[data-v-5672fde5]:hover {
  background: #0875d6;
  border-color: #0875d6;
}
[dir=ltr] .flatpickr-calendar .flatpickr-day.selected.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)), [dir=ltr] .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)), [dir=ltr] .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #0875d6;
}
[dir=rtl] .flatpickr-calendar .flatpickr-day.selected.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)), [dir=rtl] .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)), [dir=rtl] .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange[data-v-5672fde5]:not(:nth-child(7n+1)) {
  box-shadow: 10px 0 0 #0875d6;
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled[data-v-5672fde5], .flatpickr-calendar .flatpickr-day.prevMonthDay[data-v-5672fde5], .flatpickr-calendar .flatpickr-day.nextMonthDay[data-v-5672fde5] {
  color: #dae1e7;
}
[dir] .flatpickr-calendar .flatpickr-day[data-v-5672fde5]:hover {
  background: #f6f6f6;
}
.flatpickr-calendar[data-v-5672fde5]:after, .flatpickr-calendar[data-v-5672fde5]:before {
  display: none;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month[data-v-5672fde5],
.flatpickr-calendar .flatpickr-months .flatpickr-next-month[data-v-5672fde5] {
  top: -5px;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover i[data-v-5672fde5], .flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg[data-v-5672fde5],
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover i[data-v-5672fde5],
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg[data-v-5672fde5] {
  fill: #0875d6;
}
.flatpickr-calendar .flatpickr-current-month span.cur-month[data-v-5672fde5] {
  font-weight: 300;
}
[dir] .flatpickr-time input[data-v-5672fde5]:hover, [dir] .flatpickr-time .flatpickr-am-pm[data-v-5672fde5]:hover, [dir] .flatpickr-time input[data-v-5672fde5]:focus, [dir] .flatpickr-time .flatpickr-am-pm[data-v-5672fde5]:focus {
  background: #fff;
}
[dir] .dark-layout .flatpickr-calendar[data-v-5672fde5] {
  background: #161d31;
  border-color: #161d31;
  box-shadow: none;
}
.dark-layout .flatpickr-calendar .flatpickr-months i[data-v-5672fde5],
.dark-layout .flatpickr-calendar .flatpickr-months svg[data-v-5672fde5] {
  fill: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-month[data-v-5672fde5] {
  color: #b4b7bd;
}
[dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks[data-v-5672fde5] {
  box-shadow: 1px 0 0 #3b4253;
}
[dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks[data-v-5672fde5] {
  box-shadow: -1px 0 0 #3b4253;
}
.dark-layout .flatpickr-calendar .flatpickr-weekday[data-v-5672fde5] {
  color: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-day[data-v-5672fde5], .dark-layout .flatpickr-calendar .flatpickr-day.today[data-v-5672fde5]:hover {
  color: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-day.selected[data-v-5672fde5] {
  color: #fff;
}
.dark-layout .flatpickr-calendar .flatpickr-day.prevMonthDay[data-v-5672fde5], .dark-layout .flatpickr-calendar .flatpickr-day.nextMonthDay[data-v-5672fde5], .dark-layout .flatpickr-calendar .flatpickr-day.flatpickr-disabled[data-v-5672fde5] {
  color: #4e5154 !important;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  background: #283046;
  border-color: #283046;
}
[dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  box-shadow: -5px 0 0 #283046, 5px 0 0 #283046;
}
[dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5], [dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-day.inRange[data-v-5672fde5]:hover {
  box-shadow: 5px 0 0 #283046, -5px 0 0 #283046;
}
.dark-layout .flatpickr-calendar .flatpickr-day[data-v-5672fde5]:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-day[data-v-5672fde5]:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  border-color: #283046;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-days .flatpickr-day[data-v-5672fde5]:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #283046;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time[data-v-5672fde5] {
  border-color: #161d31 !important;
}
.dark-layout .flatpickr-calendar .flatpickr-time .numInput[data-v-5672fde5],
.dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm[data-v-5672fde5] {
  color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .numInput[data-v-5672fde5]:hover, [dir] .dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm[data-v-5672fde5]:hover {
  background: #161d31;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .arrowUp[data-v-5672fde5]:after {
  border-bottom-color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .arrowDown[data-v-5672fde5]:after {
  border-top-color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-time input[data-v-5672fde5]:hover, [dir] .dark-layout .flatpickr-time .flatpickr-am-pm[data-v-5672fde5]:hover, [dir] .dark-layout .flatpickr-time input[data-v-5672fde5]:focus, [dir] .dark-layout .flatpickr-time .flatpickr-am-pm[data-v-5672fde5]:focus {
  background: #161d31;
}
.flatpickr-input[readonly][data-v-5672fde5],
.flatpickr-input ~ .form-control[readonly][data-v-5672fde5],
.flatpickr-human-friendly[readonly][data-v-5672fde5] {
  opacity: 1 !important;
}
[dir] .flatpickr-input[readonly][data-v-5672fde5], [dir] .flatpickr-input ~ .form-control[readonly][data-v-5672fde5], [dir] .flatpickr-human-friendly[readonly][data-v-5672fde5] {
  background-color: inherit;
}
[dir] .flatpickr-weekdays[data-v-5672fde5] {
  margin-top: 8px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months[data-v-5672fde5] {
  -webkit-appearance: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months[data-v-5672fde5],
.flatpickr-current-month .numInputWrapper[data-v-5672fde5] {
  font-size: 1.1rem;
  transition: all 0.15s ease-out;
}
[dir] .flatpickr-current-month .flatpickr-monthDropdown-months[data-v-5672fde5], [dir] .flatpickr-current-month .numInputWrapper[data-v-5672fde5] {
  border-radius: 4px;
  padding: 2px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months span[data-v-5672fde5],
.flatpickr-current-month .numInputWrapper span[data-v-5672fde5] {
  display: none;
}
html[dir=rtl] .flatpickr-calendar .flatpickr-prev-month svg[data-v-5672fde5],
html[dir=rtl] .flatpickr-calendar .flatpickr-next-month svg[data-v-5672fde5] {
  transform: rotate(180deg);
}